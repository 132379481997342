import './App.css';
import Home from './screens/01Home';
import Leagues from './screens/02Leagues'
import League from './screens/03League'
import News from './screens/04News'
import NewsDetail from './screens/05NewsDetail';
import Teams from './screens/06Teams'
import Documents from './screens/08Documents';
import Map from './screens/09Map'
import Team from './screens/07Team'
import Calendar from './screens/10Calendar';
import Multimedia from './screens/11Multimedia';
import Legal from './screens/12Legal';

import { HashRouter as Router, Routes, Route } from 'react-router-dom';




function App() {
  return (

    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/competicio" element={<Leagues />} />
      <Route path="/competicio/:a" element={<Leagues />} />
      <Route path="/competicio/lliga/:id" element={<League />} />
      <Route path="/noticies" element={<News />} />
      <Route path="/noticies/detall/:id" element={<NewsDetail />} />
      <Route path="/equips" element={
        <Teams />} />
      <Route path="/equips/:id" element={<Team />} />
      <Route path="/documents" element={<Documents />} />
      <Route path="/calendari" element={<Calendar />} />
      <Route path="/multimedia" element={<Multimedia />} />
      <Route path="/multimedia/:id" element={<Multimedia />} />
      <Route path="/mapa" element={<Map />} />
      <Route path="/legal" element={<Legal />} />
      <Route path="*" element={<Home />} />
    </Routes>

  );
}

export default App;
